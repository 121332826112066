"use client";
import styled from "@emotion/styled";
import { Alert } from "@mui/material";
import { FaddAcknowledgement } from "components/pages/HomePage/FaddAcknowledgement";
import { useSurveillanceMode } from "hooks/useSurveillanceMode";
import { ReactNode } from "react";
import { useFlags } from "services/launch-darkly";
import { useMaybeOrder } from "services/orders/OrderLoaderContext";
import { Footer } from "./Footer";
import { Header } from "./Header";

const Content = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(5)};
  height: 100%;
`;

interface LayoutProps {
  children: ReactNode;
}

/**
 * The main application layout
 * @param props
 * @returns ReactNode
 */
export function AppLayout({ children }: LayoutProps) {
  const order = useMaybeOrder();
  const surveillanceMode = useSurveillanceMode();
  const flags = useFlags();

  return (
    <>
      <Header />
      {surveillanceMode && order ? (
        <Alert severity="warning">
          You are viewing the customer portal for {order.customer.firstName}{" "}
          {order.customer.lastName}
        </Alert>
      ) : null}
      <Content>
        {flags.showFaddAcknowledgement && <FaddAcknowledgement />}
        {children}
      </Content>
      <Footer />
    </>
  );
}
